import React, { useEffect } from "react";
import { useNavigate } from "react-router";

interface Redirect {
    to: string
}

export const Redirect: React.FC<Redirect> = ({ to }) => {
    const navigate = useNavigate();
    useEffect(() => {
        // 可添加鉴权逻辑
        navigate(to);
    }, []);
    return null;
}