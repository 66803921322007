import React,{useState} from "react";
import {useLocation,useNavigate} from 'react-router-dom'
import "./feedback.css";
import {DoneBtn} from "../../components/button"
import {ReMark} from "../../components/remark"
import {SwitchItem} from "../../components/swtichItem"
import {PickTime} from "../../components/pickTime"
import { FendiNav } from "../nav";
import axios from "axios";
import { DomainUrl } from "../../utils/config";
import { Toast } from "antd-mobile";

const feedbackURL = "pub/mission/updateMission/"

export const FeedBack: React.FC<any> = () => {
    let {state:{id,name,remark,buy,arrival,arriDate}} = useLocation()
    const navigate = useNavigate()
    const [isBuy,setBuy] = useState<boolean>(buy);
    const [isReach,setReach] = useState<boolean>(arrival);
    const [reachTime,setReachTime] = useState<any>(arriDate);
    function getRemark(text:string) {
        if (text.trim().length === 0) return
        remark = text
    }
    function pickTime(time:string){
        setReachTime(time)
    }
    async function send() {
        const localUserId = localStorage.getItem("userId")
        let result = await axios({
            url:DomainUrl+feedbackURL,
            method:"POST",
            headers: { 'Content-Type': 'application/json'},
            data:JSON.stringify({
                id:id,
                arriDate:reachTime,
                isBuy:isBuy,
                remark:remark,
                isArrival:isReach,
                userId:localUserId
                })
        })
        const {status,data:{code}} = result;
        if (status===200&&code===200) {
            Toast.show("反馈成功")
            navigate("/home")
        }
    }
    return (
        <div className="feedback-box">
            <ul>
                <FendiNav navTitle={name} visible="visible"/>
                <SwitchItem title={"是否到店:"} switchStatus={isReach}  change={(flag: boolean)=>{setReach(flag)}}/>
                <PickTime onPick={pickTime} time={reachTime} />
                <SwitchItem title={"是否购买:"} switchStatus={isBuy} change={(flag: boolean)=>{setBuy(flag)}}/>
                <ReMark mark={getRemark} title={"备注："} placeholder={"请输入备注"} initialState={remark}/>
                <DoneBtn action={send} />
            </ul>
        </div>
    )
}