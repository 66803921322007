import React,{FC, Fragment, useState, useEffect} from "react";
import "./message.css";
import {ReMark} from "../../components/remark"
import {DoneBtn} from "../../components/button"
import {FendiNav} from "../nav/index"
import {PickImage} from "../../components/pickImage"
import {Toast} from "antd-mobile";
import axios from "axios";
import { DomainUrl } from "../../utils/config";
import { DotLoading } from 'antd-mobile'

interface MessageObject {
    msgtype:string
    content:string
    done:boolean
}

const mediaURL = "pub/cp/uploadMedia"
const taskQuery = "pub/mission/getMissionByIdAndMissionStatus"
const modifyTask = "pub/mission/fixMissionStatus"
const getImagesURL = "pub/cp/getTemporaryMaterial"

export const Message: FC<any> = () => {
    let taskId = localStorage.getItem("taskId")
    let imageArr: unknown[] = [] 
    let imagesArray: MessageObject[] = []
    const [initialState,setInitialState] = useState({
        status:false,
        taskId:taskId,
        imageArr:imageArr,
        imagesArray:imagesArray
    })
    let messageContent = localStorage.getItem("messageContent")
    if (messageContent == null) messageContent = ""
    const userId = localStorage.getItem("userId")
    let remark: MessageObject = {
        msgtype:"text",
        content:messageContent,
        done:false
    }
    useEffect(()=>{
        if (taskId == null) {
            queryTasklist()
        } else {
            getDefaultImages(taskId)
        }
    },[])
    async function queryTasklist() {
        let res = await axios({
            url:DomainUrl+taskQuery,
            method:"POST",
            data:{
                userId: userId,
                missionStatus: 0
            }
        })
        const {data:{code,result}} = res
        if (code === 200) {
            if (result) {
               const task = result[0]
               getDefaultImages(task.id)
            }
         }
    }

    async function getDefaultImages(taskId: string) {
       let res = await axios({
            url:`${DomainUrl+getImagesURL}?missionId=${taskId}`,
            method:"GET",
        })
        const {status, data:mediaArr} = res
  
        if (status===200 && mediaArr instanceof Array) {
            const imagesArr = mediaArr.map((media)=>{
                return {msgtype:"image",content:media.mediaId,done:false} 
            })
            setInitialState({
                status: true,
                taskId: taskId,
                imageArr: mediaArr,
                imagesArray:imagesArr
            })
        }else {
            setInitialState({
                status: true,
                taskId: null,
                imageArr: [],
                imagesArray:[]
            })  
        }
    }
    function getRemark(text:string) {
        if (text.trim().length === 0) return
        remark.msgtype = "text"
        remark.content = text
        remark.done = false
    }
    function getImages(images:string[]) {
        initialState.imagesArray = images.map((image,index)=>{
            return {msgtype:"image",content:image,done:false}
        })
    }
    function doNext() {
        let sendArray: any[]=[]
        if(remark.content.trim().length===0&&initialState.imagesArray.length===0) {
            Toast.show({content: '发送内容不能为空'})
            return
        }
        if (remark.content.trim().length>0) {
            if (initialState.imagesArray.length===0){
                remark.done = true
                updateTask()
                sendMessage(remark)()
                return 
            } else {
                sendArray.push(sendMessage(remark)) 
            }
        }
        if (initialState.imagesArray.length>0) {
            for (let index = 0; index < initialState.imagesArray.length; index++) {
                const messageObj = initialState.imagesArray[index];
                sendArray.push(sendMessage(messageObj))
            }
        }
        updateTask()
        executeTasks(sendArray)
    }
    function openEnterpriseChat() {
        let jsSdK = (window as any) 
        jsSdK.wx.openEnterpriseChat({
            userIds: localStorage.getItem("userId"),    
            externalUserIds: localStorage.getItem("externalUserIds"),
            groupName: '',
            chatId: '', 
            success: function(res:any) {
            },
            fail: function(res:any) {
                if(res.errMsg.indexOf('function not exist') > -1){
                    alert('版本过低请升级')
                }
            }
        });
    } 
    async function executeTasks(tasks:any[]) {
        if (tasks.length === 0){
            openEnterpriseChat()
            return
        }
        const task = tasks.shift();
        await task()
        await executeTasks(tasks)
    }
   
    function sendMessage(message: MessageObject) {
        return ()=> {
            return new Promise((reslove)=>{
                let jsSdK = (window as any) 
                jsSdK.wx.invoke('sendChatMessage', {
                    msgtype:message.msgtype, 
                    enterChat: message.done, 
                    text: {
                        content: message.content,
                    },
                    image: {
                        mediaid: message.content
                    }
                },function(res:any) {
                    if (res.err_msg === 'sendChatMessage:ok') {
                        if (message.msgtype==="image") {
                            axios({
                                url:`${DomainUrl + mediaURL}`,
                                method:"POST",
                                data:{
                                    mediaId:message.content,
                                    missionId:initialState.taskId
                               }
                            }).then((res)=>{
                                reslove(1)
                            })
                        } else {
                            reslove(1)  
                        }
                    }
                }) 
            })
        }
    }
    function updateTask() {
        axios({
            url:DomainUrl + modifyTask,
            method:"POST",
            data:{
                id: initialState.taskId,
                messageText:remark.content.trim()
            }
        }).then((res)=>{
            localStorage.removeItem("taskId")
        })
    }
    let contentView 
    if (initialState.status) {
        if (initialState.taskId == null) {
            contentView = <div className="box-empty">暂无沟通任务</div>
        } else {
            contentView =  (<Fragment>
                                <FendiNav navTitle={"任务内容"} visible="hidden" />
                                <ReMark mark={getRemark} title={"文案："} initialState={messageContent} />
                                <PickImage pick={getImages} initialState={initialState.imageArr} />
                                <DoneBtn action = {doNext} />
                            </Fragment>)
        } 
    } else {
        contentView = <div className="message-loading"><DotLoading /></div>
    }
    return (
        <div>
          {contentView}
        </div>
    )
}