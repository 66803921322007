import React,{FC} from "react";
import './guide.css'
import { FendiNav } from "../nav/index";

export const Guide: FC<any> = ()=>{
    return (
        <div>
            <FendiNav navTitle={"任务指引"} visible="visible" />
            <img className="guide-img" src={require("./guide.png")} alt=""/>
        </div>
    )
}