import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { routes } from "../routes";
import { Redirect } from "../components/redirect";
import { wxConfig } from "../utils/wx-sdk-config";
import CommonContext from "./context";

export const Root: React.FC<any> = () => {
    const [data,setData] = useState<any>("other");
    useEffect(() => {
        wxConfig().then((entry)=>{
            setData(entry)
        })
    },[])
    return (
        <div>
            <CommonContext.Provider value = {data} >
                <Router>
                    <Routes>
                        <Route key="/" path="/" element={<Redirect to="/entrance" />} />
                        {
                            routes.map(val => (
                                <Route key={val.path} path={val.path} element={val.element()} />
                            ))
                        }
                    </Routes>
                </Router>
            </CommonContext.Provider>
            
        </div>
    )
}