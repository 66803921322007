import React,{FC, useState} from "react"
import "./itemDone.css"
import { useNavigate } from "react-router"
import ItemData from "../model/TaskModel"
import { Detail } from "../detail/detail"
import { stars } from "./star/star";
import { getMDHM,dateHFormat } from "../../../utils/dateTool"
import buddy from "./buddy"

export const ItemDone: FC<ItemData> = (item)=>{
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    function feedback() {
        navigate("/feedback",{state:{
            id:item.id,
             name:item.customerName,
             externalUserId:item.externalUserId,
             remark:item.remark,
             buy:item.buy,
             arrival:item.arrival,
             arriDate:dateHFormat(item.arriDate)
            }})
    }
    function showDetail() {
        setVisible(true)
    }
    function closeDetail() {
        setVisible(false)
    }
    function openEnterpriseChat() {
        let jsSdK = (window as any) 
        jsSdK.wx.openEnterpriseChat({
            userIds: localStorage.getItem("userId"),    
            externalUserIds: item.externalUserId,
            groupName: '',
            chatId: '', 
            success: function(res:any) {
                 var chatId = res.chatId; 
                 localStorage.setItem("externalUserIds",`${item.externalUserId}`)
                 localStorage.setItem("chatId",chatId)
                 localStorage.setItem("taskId",item.id)
                 localStorage.setItem("messageContent",item.suggestCommunicationDirection)
            },
            fail: function(res:any) {
                if(res.errMsg.indexOf('function not exist') > -1){
                    alert('版本过低请升级')
                }
            }
        });
    } 
    const wh = (document.body.clientWidth - 10 - 120)/4
    const star = item.customerConstellation.trim().slice(0,2)
    let imgList
    if(item.imgUrlList!= null) {
        imgList = item.imgUrlList.map((url,index)=>{
            return (<li className="item-img" style={{width:`${wh}px`,height:`${wh}px`}} key={index} >
                        <img src={url} alt=""  style={{width:`${wh}px`,height:`${wh}px`}} onClick={()=>preview(index)}/>
                    </li>)
        })
    } else {
        imgList = <></>
    }
    function preview(index:number) {
        
        let jsSdK = (window as any) 
        jsSdK.wx.previewImage({
            current:item.imgUrlList[index],
            urls:item.imgUrlList
        });
    }
    return (
        <li className="item-box-done">
            <Detail visible={visible} onClose={closeDetail} {...item}/>
             <div className="item-star">
                <img className="guide-img" src={stars.get(star)} alt=""/>
                <span>{star}座</span>
            </div>
            <div className="item-header">
                <span className="item-title">发送至:</span>
                <img  className="item-buddy" src={item.isEnterpriseWechatFriend?buddy.buddyIcon:buddy.unbuddyIcon} alt="好有关系"/>
                <span className="item-name" onClick={openEnterpriseChat}>{item.customerName}</span>
                <span className="item-detail" onClick={showDetail}>{"详情 >"}</span>
            </div>
            <div className="item-content-done">
                <span className="done-title"> 文案：</span>
                <p className="done-content">{item.messageText}</p>
            </div>
            <div className="item-img-box" style={{display:item.imgUrlList!=null?'':'none'}}>
                <span className="item-img-title"> 图片：</span>
                 <ul className="item-img-list">
                 {imgList}
                </ul>
            </div>
            <div className="item-time">
                <span>沟通时间：{getMDHM(item.communicationDate)}</span>
            </div>
          <button className="feedback" onClick={feedback}>跟踪反馈</button> 
        </li>
    )
}