import React, { FC } from "react";
import "./switchItem.css";

interface SwitchOperate {
    title: string
    switchStatus: boolean
    change:(flag:boolean)=>void
}

export const SwitchItem: FC<SwitchOperate> = (switchOpt) => {
    const {title,switchStatus,change} = switchOpt
    return (
            <li className="switchItem-box">
                <span id="title">{title}</span>
                <div>
                    <button className={switchStatus ? "active left" : "left"} onClick={()=>{change(!switchStatus)}}>是</button>
                    <button className={switchStatus ? "right" : "active right"} onClick={()=>{change(!switchStatus)}}>否</button>
                </div>
            </li>
    )
}