import React,{FC} from "react"
import './statusBar.css'

export const StatusBar: FC<{status:number,modify:(status:number)=>void}> = ({
    status,
    modify=()=>{}
})=>{
    return (
        <center className="box-tool-bar">
            <button className={status===1?'item-nomal':'item-nomal item-active'} onClick={()=>modify(0)}>未完成</button>
            <button className={status===1?'item-nomal item-active':'item-nomal'} onClick={()=>modify(1)}>已完成</button>
        </center>
    )
}