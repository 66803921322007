export function getTimestamp(date:Date = new Date()):number {
    return date.getTime()
}

//毫秒时间戳
export function getDate(timestamp:number):string {
    const date = new Date(timestamp)
    return date.toLocaleTimeString()
}
export function getMDHM(timestamp:number):string {
    if(timestamp===null||timestamp===0){
        return "暂无"
    }
    const date = new Date(timestamp)
    let minute = ""
    if (date.getMinutes()<10) {
        minute = `0${date.getMinutes()}`
    } else {
        minute = `${date.getMinutes()}`
    }
    return `${date.getMonth()+1}-${date.getDate()} ${date.getHours()} : ${minute}`
}

export function getchMDHM(timestamp:number):string {
    if(timestamp==null) {
        return "暂无"
    }
    const date = new Date(timestamp)
    return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${date.getHours()}时`
}

export function dateFormat(chinaStandard?:string):string {
    if (chinaStandard === undefined) {
        let date = new Date()
        let year:number = date.getFullYear()
        let month:number = date.getMonth() + 1
        let day:number = date.getDate()
        let hour:number = date.getHours()
        let minute:number = date.getMinutes()
        let m = dateString(month)
        let d = dateString(day)
        let h = dateString(hour)
        let minu = dateString(minute)
        return `${year}-${m}-${d} ${h}:${minu}`
    } else {
        let date = new Date(chinaStandard)
        let year:number = date.getFullYear()
        let month:number = date.getMonth() + 1
        let day:number = date.getDate()
        let hour:number = date.getHours()
        let minute:number = date.getMinutes()
        let m = dateString(month)
        let d = dateString(day)
        let h = dateString(hour)
        let minu = dateString(minute)
        return `${year}-${m}-${d} ${h}:${minu}`
    }
}

export function dateHFormat(chinaStandard?:string|number):string {
    if(chinaStandard === null||chinaStandard===0) {
        return ""
    } else if (chinaStandard === undefined) {
        let date = new Date()
        let year:number = date.getFullYear()
        let month:number = date.getMonth() + 1
        let day:number = date.getDate()
        let hour:number = date.getHours()
        let m = dateString(month)
        let d = dateString(day)
        let h = dateString(hour)
        return `${year}-${m}-${d} ${h}`
    }else {
        let date = new Date(chinaStandard)
        let year:number = date.getFullYear()
        let month:number = date.getMonth() + 1
        let day:number = date.getDate()
        let hour:number = date.getHours()
        let m = dateString(month)
        let d = dateString(day)
        let h = dateString(hour)
        return `${year}-${m}-${d} ${h}`
    }
}

export function dateChFormat(chinaStandard?:string):string {
    if (chinaStandard === undefined) {
        let date = new Date()
        let year:number = date.getFullYear()
        let month:number = date.getMonth() + 1
        let day:number = date.getDate()
        let hour:number = date.getHours()
        let minute:number = date.getMinutes()
        let m = dateString(month)
        let d = dateString(day)
        let h = dateString(hour)
        let minu = dateString(minute)
        return `${year}-${m}-${d} ${h}:${minu}`
    } else {
        let date = new Date(chinaStandard)
        let year:number = date.getFullYear()
        let month:number = date.getMonth() + 1
        let day:number = date.getDate()
        let hour:number = date.getHours()
        let minute:number = date.getMinutes()
        let m = dateString(month)
        let d = dateString(day)
        let h = dateString(hour)
        let minu = dateString(minute)
        return `${year}-${m}-${d} ${h}:${minu}`
    }
}

//为小于10的数字前面加0并转字符串
function dateString(n:number):string {
    if(n<10) {
        return `0${n.toString()}`
    } else {
        return n.toString()
    }
}

export function timestamp(chinaStandard:string):number {
    let date = new Date(chinaStandard)
    return date.getMilliseconds()

}



