import React,{FC,useState} from "react";
import "./pickTime.css";
import {DatePicker} from 'antd-mobile'
import {dateHFormat} from '../../utils/dateTool'

export const PickTime: FC<any> = (props) => {
    const [visible, setVisible] = useState(false)
    const [time, setTime] = useState(props.time)
    return (
        <li className="pick-time-box">
            <span className="title">到店时间：</span>
            <div>
              <span>{time}</span>
              <img src={require("./time.png")} alt="" onClick={()=>{setVisible(true)}}/>
            </div>
            <DatePicker visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                precision='hour'
                max={new Date()}
                onConfirm={val => {
                    const time = dateHFormat(val as any) 
                    setTime(time)
                    props.onPick(time)
                }}
            />
        </li>
    )
}