import React,{FC,useState} from "react"
import { useNavigate } from "react-router"
import './item.css'
import ItemData from '../model/TaskModel'
import { Detail } from "../detail/detail"
import { stars } from "./star/star"
import buddy from "./buddy"
import { Unbuddy } from "../detail/unbuddy"


export const Item: FC<ItemData> = (item)=>{
    let navigate  = useNavigate()
    const [visible, setVisible] = useState(false)
    const [unbuddyVisible, setUnbuddyVisible] = useState(false)
    function showDetail() {
        setVisible(true)
    }
    function closeDetail() {
        setVisible(false)
        setUnbuddyVisible(false)
    }
    function openEnterpriseChat() {
        if (item.isEnterpriseWechatFriend === 1) {
            // localStorage.setItem("taskId","8aaa80fb8924a5cc0189250047a60027")
            // const defaultImages = {
            //                     "one": item.defaultCommunicationPictureOne,
            //                     "two": item.defaultCommunicationPictureTwo,
            //                     "three": item.defaultCommunicationPictureThree,
            //                     "four": item.defaultCommunicationPictureFour,
            //                     "five": item.defaultCommunicationPictureFive,
            //                     "six": item.defaultCommunicationPictureSix,
            //                 }
            // localStorage.setItem("defalutImages",JSON.stringify(defaultImages))
            // navigate("/message")
            let jsSdK = (window as any) 
            jsSdK.wx.openEnterpriseChat({
                userIds: localStorage.getItem("userId"),    
                externalUserIds: item.externalUserId,
                groupName: '',
                chatId: '', 
                success: function(res:any) {
                        var chatId = res.chatId; 
                        localStorage.setItem("externalUserIds",`${item.externalUserId}`)
                        localStorage.setItem("chatId",chatId)
                        localStorage.setItem("taskId",item.id)
                        localStorage.setItem("messageContent",item.suggestCommunicationDirection)
                },
                fail: function(res:any) {
                    if(res.errMsg.indexOf('function not exist') > -1){
                        alert('版本过低请升级')
                    }
                }
            });
        } else {
            setUnbuddyVisible(true)
        }
    } 
    const star = item.customerConstellation.trim().slice(0,2)
     return (
        <li className="item-box" id="item-box-done">
            <Detail visible={visible} onClose={closeDetail} {...item}/>
            <Unbuddy  visible={unbuddyVisible} onClose={closeDetail} {...item}/>
             <div className="item-star">
                <img className="guide-img" src={stars.get(star)} alt=""/>
                <span>{star}座</span>
            </div>
            <div className="item-header">
                <span className="item-title">发送至:</span>
                <img className="item-buddy" src={item.isEnterpriseWechatFriend?buddy.buddyIcon:buddy.unbuddyIcon} alt="好有关系"/>
                <span className="item-name">{item.customerName}</span>
                <span className="item-detail" onClick={showDetail}>{"详情 >"}</span>
            </div>
            <div className="item-content-box">
                <span className="item-content-title"> 文案：</span>
                <p className="item-content">{item.suggestCommunicationDirection}</p>
            </div>
            <center><button className="feedback" onClick={openEnterpriseChat}>做任务</button></center>  
        </li>
    )
}