import React,{ChangeEvent, useState} from "react";
import "./remark.css";

interface RemarkProps{
    title:string,
    placeholder?:string,
    initialState?:any,
    mark:(val:string)=>void
}

export const ReMark: React.FC<RemarkProps> = ({initialState,...props}) => {
    const [remark, setRemark] = useState(initialState)
    function callbackValue(e:ChangeEvent) {
        const remark = (e.target as HTMLTextAreaElement).value;
        setRemark(remark)
        props.mark(remark)
    }
    return (
        <li className="remark-box">
            <span className="title">{props.title}</span>
            <div>
                <textarea placeholder={props.placeholder} onChange={callbackValue} value={remark}></textarea>
            </div>
        </li>
    )
}