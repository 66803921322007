import React from "react"
import { Mask } from "antd-mobile"
import "./detail.css"
import detailIcon from './detail-icon.png'
import close from './close.png'

interface DetailItem {
    title:string
    value:string
}

export const Detail: React.FC<any> = (props) => {
    const vipData:DetailItem[] = [
        {title:"会员等级：",value:props.level},
        {title:"客人类型：",value:props.customerType},
   ]
   const payData: DetailItem[] = [
        {title:"过去2年消费：",value:props.consumptionInThePastTwoYears},
        {title:"LGS：",value:props.lgsConsumption},
        {title:"RTW：",value:props.rtwConsumption},
        {title:"SHOES：",value:props.shoesConsumption},
        {title:"ACC：",value:props.accConsumption},
        {title:"FUR：",value:props.furConsumption},
    ]
    const productData: DetailItem[] = [
        {title:"最近购买产品：",value:props.lastPurchasedProductName},
        {title:"之前购买产品1：",value:props.previouslyPurchasedProductNameOne},
        {title:"之前购买产品2：",value:props.previouslyPurchasedProductNameTwo},
        {title:"之前购买产品3：",value:props.previouslyPurchasedProductNameThree},
        {title:"之前购买产品4：",value:props.previouslyPurchasedProductNameFour},
        {title:"之前购买产品5：",value:props.previouslyPurchasedProductNameFive},
        {title:"之前购买产品6：",value:props.previouslyPurchasedProductNameSix},
        {title:"之前购买产品7：",value:props.previouslyPurchasedProductNameSeven},
        {title:"之前购买产品8：",value:props.previouslyPurchasedProductNameEight},
        {title:"之前购买产品9：",value:props.previouslyPurchasedProductNameNine}
    ]
    const payItems = payData.map((item:DetailItem,index:number)=>{
        return (
                <li className="detail-item" key={index}>
                    <span className="title">{item.title}</span>
                    <span className="content">{item.value}</span>
                </li>
        )
    })
    const vipItems = vipData.map((item:DetailItem,index:number)=>{
        return (
                <li className="detail-item" key={index}>
                    <span className="title">{item.title}</span>
                    <span className="content">{item.value}</span>
                </li>
        )
    })
    const productTems = productData.map((item:DetailItem,index:number)=>{
        return (
                <li className="detail-item product-item" key={index}>
                    <span className="title">{item.title}</span>
                    <div className="product-content">
                        <span className="content">{item.value}</span>
                    </div>
                </li>
        )
    })
    return (
        <Mask visible={props.visible} onMaskClick={() => props.onClose(false)}>
            <div className="detail-bg">
                <div className="detail-close">
                    <img className="close" src={close} alt="" onClick={() => props.onClose(false)} />
                </div>
                <ul className="detail-box">
                    <li className="detail-item detail-header">
                        <span className="title">会员等级</span> 
                        <img src={detailIcon} alt="" />
                    </li>
                    {vipItems}
                    <li className="detail-item detail-header">
                        <span className="title">消费情况</span> 
                        <img src={detailIcon} alt="" />
                    </li>
                    {payItems}
                    {productTems}
                </ul>
            </div>
        </Mask>
    )
}