import React, { useState } from "react"
import "./home.css"
import { Header } from "./header/header"
import { List } from "./list/list"
import { ListDone } from "./list/listDone"
import { StatusBar } from "./statusBar/statusBar"
import { FendiNav } from "../nav/index"

export const Home: React.FC<any> = () => {
    const [status,setStatus] = useState<number>(0)
    let listView 
    if (status===0) {
        listView = <List />
    } else {
        listView = <ListDone />
    }
    return (
        <div>
            <FendiNav navTitle={"客户沟通任务"} visible="hidden" />
            <Header />
            {listView}
            <StatusBar status={status} modify={(status:number)=>{setStatus(status)}} />
        </div>
    )
}