import React,{FC} from "react"
import './fendiNav.css'
import { useNavigate } from "react-router"
import back from './left-back.png'

export const FendiNav: FC<{navTitle:string,visible:any}> = ({navTitle,visible})=>{
    const navigate = useNavigate()
    return (
            <div id="fendiNav">
                <div id="fendiNav-back" onClick={()=>{ navigate(-1) }}>
                    <img src={back} alt="" style={{visibility:visible}}/>
                </div>
                <span>{navTitle}</span>
            </div>
    )
}