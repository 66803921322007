import axios from "axios";
import { DomainUrl } from "./config";

// 获取wx接口需请求的服务器api地址
// const wxConfigApi = "pub/cp/createJsapiSignature";  
const wxConfigApi = "pub/cp/createBothSignature";  
export const wxConfig = async () => {
    const path = window.location.href.split("#")[0];
    let jsSdK = (window as any) 
    return new Promise((resolve,reject)=>{
        axios({
            url:`${DomainUrl + wxConfigApi}`,
            method:"POST",
            headers:{'Content-Type':'text/plain'},
            data:path
        }).then((result: any) => {
            const {data:{agentJsapiSignature,jsapiSignature}} = result;
            jsSdK.wx.config({
                debug: false,
                appId: jsapiSignature.appId,
                timestamp: jsapiSignature.timestamp,
                nonceStr: jsapiSignature.nonceStr,
                signature: jsapiSignature.signature,
                jsApiList:["chooseImage","previewImage","openEnterpriseChat"]
            });
            jsSdK.wx.ready((e:any)=>{
                (window as any).wx.agentConfig({
                    corpid: agentJsapiSignature.corpid, 
                    agentid: agentJsapiSignature.agentid, 
                    timestamp: agentJsapiSignature.timestamp, 
                    nonceStr: agentJsapiSignature.nonceStr, 
                    signature: agentJsapiSignature.signature,
                    jsApiList: ["openExistedChatWithMsg","selectEnterpriseContact","sendChatMessage",
                    "selectExternalContact","openEnterpriseChat","getContext","getCurExternalContact"], 
                    success: function(res:any) { 

                        jsSdK.wx.invoke('getContext', {
                        }, function(res:any){
                        if(res.err_msg === "getContext:ok") {
                            const entry  = res.entry;
                            resolve(entry);
                        } else {
                            reject("失败");
                        }
                    })
                    },
                    fail: function(res:any) {
                        if(res.errMsg.indexOf('function not exist') > -1){
                            alert('版本过低请升级')
                        }
                    }    
                })
                }) 
        });
    })
}