
import one  from "./1.png";
import two  from "./2.png";
import three  from "./3.png";
import four  from "./4.png";
import five  from "./5.png";
import six  from "./6.png";
import seven  from "./7.png";
import eigth from "./8.png";
import nine  from "./9.png";
import ten  from "./10.png";
import eleven  from "./11.png";
import twelve  from "./12.png";

export const stars: Map<string,string> = new Map([
    ['水瓶',one],
    ['双鱼',two],
    ['白羊',three],
    ['金牛',four],
    ['双子',five],
    ['巨蟹',six],
    ['狮子',seven],
    ['处女',eigth],
    ['天秤',nine],
    ['天蝎',ten],
    ['射手',eleven],
    ['摩羯',twelve]
]) 