import React,{useState} from "react";
import "./pickImage.css";
import {ImageItem} from './imageItme'

export const PickImage: React.FC<any> = (props) => {
   let imgurl = props.initialState.map((media:any)=>{
        return media.img
    })
    let mediaIdArr = props.initialState.map((media:any)=>{
        return media.mediaId
    })
    const [imgArr,setImgArray] = useState<string[]>([...imgurl])
    const [serviceIdArray,setServiceIdArray] = useState<string[]>([...mediaIdArr]);
    function chooseImage() {
        let jsSdK = (window as any) 
        jsSdK.wx.chooseImage({
            count: 1,
            sizeType: ['original', 'compressed'],
            sourceType: ['album', 'camera'], 
            isSaveToAlbum: 1, 
            success: function (res: any) {
                var localId = res.localIds[0]
                getlocalImage(localId)
            }
        });
    }

    function getlocalImage(localId: string) {
        let jsSdK = (window as any)
        jsSdK.wx.getLocalImgData({
            localId: localId, 
            success: function (res:any) {
                var localData = res.localData;
                uploadImage(localId,localData)
            }
        });
    }

    function uploadImage(localId: string,localData: any) {
        let jsSdK = (window as any)
        jsSdK.wx.uploadImage({
            localId: localId,
            isShowProgressTips: 1,
            success: function (res:any) {
                imgArr.push(localData)
                var serverId = res.serverId; 
                serviceIdArray.push(serverId)
                setImgArray([...imgArr])
                setServiceIdArray([...serviceIdArray])
                props.pick(serviceIdArray)
            }
        }); 
    }
    function delImge(index: number){
        return ()=>{
            imgArr.splice(index,1)
            serviceIdArray.splice(index,1)
            setImgArray([...imgArr])
            setServiceIdArray([...serviceIdArray])
            props.pick(serviceIdArray)
        }
    }
    const wh = (document.body.clientWidth - 20 - 60)/3
    return (
        <div className="pick-img-box">
           <span className="title">图片：</span>
            <ul className="pick-img-list">
                {
                    imgArr.map((img:string,index:number)=>{
                        return  <ImageItem key={`${img}:${index}`} data = {img} del={delImge(index)} wh={wh} />
                    })
                }
                 <li id ="pick-placeHoulder"  onClick={chooseImage} style={{width:`${wh}px`,height:`${wh}px`}}>
                    <img src={require("./pick.png")} alt=""/>
                 </li>
            </ul>
        </div>
    )
}