import React, {FC} from "react";
import './imageItem.css'
import close from './close.png'

export const ImageItem: FC<{data:string,del:()=>void,wh:number}> = ({data,del,wh}) => {  
    return (
        <li className="img-card" style={{width:`${wh}px`,height:`${wh}px`}}>
            <img src={data} alt=""/>
            <img className="delent-btn" src={close} onClick={del} alt="" />
        </li>
    )
}