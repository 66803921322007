import React,{FC,useEffect, useState} from "react"
import './list.css'
import {Item} from "../Item/item"
import axios from "axios"
import ItemData from '../model/TaskModel'
import { DomainUrl } from "../../../utils/config"

const taskQuery = "pub/mission/getMissionByIdAndMissionStatus"

export const List: FC<any> = ()=>{
    const [objArray,setObjArray] = useState([])
    useEffect(()=>{
        const userId = localStorage.getItem("userId")
        getTask(userId)
    },[])
    function delItem(index:number) {
        objArray.splice(index,1)
        setObjArray([...objArray])
    }
    async function getTask(userId:string|null) {
        if (userId===null) return
        const url = DomainUrl + taskQuery
        let res = await axios({
              url:url,
              method:"POST",
              data:{
                userId:userId,
                missionStatus:0
              }
         })
         const {data:{code,result}} = res
         if (code === 200) {
            setObjArray(result)
         }
    }
    let listItems =  objArray.map((item:ItemData,index:number)=>{
        if (item.isEnterpriseWechatFriend !== 1) {
            item.delCallback = delItem
        }
        item.index = index;
        return <Item key={item.id}  {...item} />
    })
    return (<ul className="box-item">{listItems}</ul>)
}


