import React, { useState } from "react"
import { Mask } from "antd-mobile"
import './unbuddy.css'
import buddy from "../Item/buddy"
import close from './close.png'
import { DomainUrl } from "../../../utils/config"
import axios from 'axios'

const modifyTask = "pub/mission/fixMissionStatus"

export const Unbuddy: React.FC<any> = (props) => {
    const [remark, setRemark] = useState(props.suggestCommunicationDirection)
    function updateTask() {
        axios({
            url:DomainUrl + modifyTask,
            method:"POST",
            data:{
                id: props.id,
                messageText:remark.trim()
            }
        }).then((res)=>{
            props.onClose(false)
            props.delCallback(props.index)
        })
    }
    return (
        <Mask visible={props.visible} onMaskClick={() => props.onClose(false)} >
            <div className="unbuddy-bg">
            <div className="unbuddy-close">
                    <img className="close" src={close} alt="" onClick={() => props.onClose(false)} />
                </div>
                <div className="unbuddy-header">
                    <img src={buddy.buddyTitleIcon} alt=""/>
                    <h4>该客户不是您的好友，请先与客户进行电话或其他方式进行沟通，沟通完成后可点击下方按钮来完成任务。</h4>
                </div>
                <div className="unbuddy-remark">
                    <span className="title">推荐文案：</span>
                    <div>
                        <textarea value={remark} onChange={(e)=>setRemark(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="footer" onClick={updateTask}>
                     <span>确认完成任务</span>
                </div>
            </div>
        </Mask>
    )
}