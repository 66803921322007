import React,{FC} from "react";
import "./button.css";

export const DoneBtn: FC<any> = (props) => {
    function send(){
        props.action()       
    }
    return (
        <button className="box-tool-bar" onClick={send}>确定</button> 
    )
}