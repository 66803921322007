import { Home } from "../views/home"
import { Guide } from "../views/guide/guide"
import { Message } from "../views/message/index"
import { FeedBack } from "../views/feedback"
import { Entrance } from "../views/entrance"

export const routes = [
    
    {
        path:"/entrance",
        element:()=><Entrance />
    },
    {
        path:"/home",
        element:()=><Home />
    },
    {
        path:"/message",
        element:()=><Message />
    },
    {
        path:"/feedback",
        element:()=><FeedBack />
    },
    {
        path:"/guide",
        element:()=><Guide />
    }

]