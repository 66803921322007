import React,{FC, useEffect,useState} from "react"
import './list.css'
import {ItemDone} from "../Item/itemDone"
import axios from "axios"
import ItemData from '../model/TaskModel'
import { DomainUrl } from "../../../utils/config"

const taskUrl = "pub/mission/getMissionByIdAndMissionStatus"

export const ListDone: FC<any> = ()=>{
    const [objArray,setObjArray] = useState([])
    useEffect(()=>{
        const userId = localStorage.getItem("userId")
        getTask(userId)
    },[])

    async function getTask(userId:string|null) {
        if (userId==null) return
        const url = DomainUrl + taskUrl
        let res = await axios({
              url:url,
              method:"POST",
              data:{
                userId:userId,
                missionStatus:1
              }
         })
         const {data:{code,result}} = res
         if (code === 200) {
            setObjArray(result)
         }
    }
    let listItems =  objArray.map((item:ItemData)=><ItemDone key={item.id}  {...item} />)
    return (<ul className="box-item">{listItems}</ul>)
}


