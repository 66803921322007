import React,{FC} from "react";
import './header.css'
import { useNavigate } from "react-router";

export const Header: FC<any> = ()=>{
    const navigate = useNavigate()
    return (
        <div className="header-bg">
            <div className="header-search">
             <img src={require("./search-icon.png")} alt=""/>
             <input type="text" placeholder="输入客户姓名搜索任务"/>
            </div>
            <div className="header-guide" onClick={() => navigate("/guide")}>
                <img src={require("./task-guide.jpg")} alt=""/>
                <span>任务指引</span> 
            </div>
        </div>
        
    )
}
