import React,{FC,useContext,useEffect, useState} from "react";
import { Home } from "./home";
import { Message } from './message/index'
import axios from "axios"
import CommonContext from "../root/context";
import { DomainUrl,ReDomainUrl} from "../utils/config"
import { DotLoading } from 'antd-mobile'
import "./entrance.css"

const qyauthUrl = "pub/cp/buildAuthorizationUrlPrivate"
const userinfoUrl = "pub/cp/getUserInfo"

export const Entrance: FC<any> = () => {
    const state = useContext(CommonContext)
    let [userinfoFlag, setUserinfoFlag] = useState(true)
    //控制页面显示是否空白,微信SDK初始化
    let waitFlag = true
    //控制显示哪个组件
    let selectFlag = true
    useEffect(()=>{
        const localUserId = localStorage.getItem("userId")
        if (localUserId == null) {
            const authInfo = localStorage.getItem("userAuth")
            const search = window.location.search
            if (authInfo==null||search.length===0) {
                authStatus()
            } else {
                getUserInfo()
            }
        }
    },[]) 

    async function authStatus() {
        let result = await axios({
            url:`${DomainUrl + qyauthUrl}`,
            method:"POST",
            headers: {'Content-Type':'text/plain'},
            data: ReDomainUrl
        })
        const {status, data} = result
        if (status === 200) {
            localStorage.setItem("userAuth", "userAuth");
            window.location.href = data
        } 
    }

    async function getUserInfo() {
            const localUserId = localStorage.getItem("userId")
            if (localUserId!==null) {
                setUserinfoFlag(false)
                return
            }
            const search = window.location.search
            if (search.length<=0) return 
            if (search.split("code=",2).length<=0)  return 
            const codePath = search.split("code=",2)[1]
            if (codePath.length<0) return
            const code = codePath.split("&",1)[0]
            if (code.length<=0) return
            let result = await axios({
                url:`${DomainUrl + userinfoUrl}`,
                method:"POST",
                headers: {'Content-Type':'text/plain'},
                data:code
            })
            const {status, data:{userId}} = result
            if (status === 200) {
                localStorage.setItem("userId", userId)
                setUserinfoFlag(false)
            }
    }
    if (state==="normal") {
        waitFlag = false
        userinfoFlag = false
        selectFlag = true
    } else if (state==="single_chat_tools") {
        waitFlag = false
        selectFlag = false
        userinfoFlag = true
    }
    if (state==="other") {
        waitFlag = true
        userinfoFlag = true
    }
    // waitFlag = false
    // selectFlag = true
    // userinfoFlag = false
    let initView
    if (waitFlag&&userinfoFlag) {
        initView = <div className="entrance-loading"><DotLoading /></div>
    } else {
        if (selectFlag) {
            initView  = <Home />
        } else {
            initView = <Message/>
        }
    }
    return (
        <div>{initView}</div>
    )
}