import buddyIcon from './buddy.png'
import unbuddyIcon from './unbuddy.png'
import buddyTitleIcon from './unbuddy_title.png'


const buddy = {
    buddyIcon,
    unbuddyIcon,
    buddyTitleIcon
}

export default buddy